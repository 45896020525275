import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./Home";
import About from "./About";
import Contact from "./Contact";
import OurWork from "./OurWork";
import Team from "./Team";
import NotFound from "./NotFound";
import DigitalPhotography from "./DigitalPhotography";
import Drone from "./Drone";

import AppDraw from "../layout/AppDraw";
import Footer from "../layout/Footer";
import ScrollToTop from "./ScrollToTop";
import PrivacyPolicy from "./PrivacyPolocy";
import ThreeSixtyPhotography from "./ThreeSixtyPhotography";
import VideoProduction from "./VideoProduction";

export default function Routes() {
  return (
    <Router>
      <ScrollToTop>
        <AppDraw />
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/team">
            <Team />
          </Route>
          <Route path="/ourWork">
            <OurWork />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/digitalPhotography">
            <DigitalPhotography />
          </Route>
          <Route path="/dronePhotography">
            <Drone />
          </Route>
          <Route path="/privacyPolicy">
            <PrivacyPolicy />
          </Route>
          <Route path="/threeSixty">
            <ThreeSixtyPhotography />
          </Route>
          <Route path="/videoProduction">
            <VideoProduction />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
        <Footer />
      </ScrollToTop>
    </Router>
  );
}
