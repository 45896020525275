import React from "react";
import clsx from "clsx";
import {
  createStyles,
  makeStyles,
  Theme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Grid, Hidden, IconButton, Typography } from "@material-ui/core";
import useStylesBase from "../styles/stylesBase";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import MenuOpenRoundedIcon from "@material-ui/icons/MenuOpenRounded";
import { Link } from "react-router-dom";
import Logo from "../assets/appBar/mainLogo.svg";
import MobileLogo from "../assets/appBar/mobileLogo.svg";

import Photo from "../assets/appBar/CameraIcon";
import Drone from "../assets/appBar/DroneIcon";
import ThreeSixtyIcon from "../assets/appBar/ThreeSixtyIcon";
import Video from "../assets/appBar/VideoIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 999,
      padding: 10,
    },
    list: {
      width: 500,
      maxWidth: "100vw",
      padding: 10,
      color: "white",
    },
    fullList: {
      width: "auto",
    },
    icon: {
      color: "#fff",
      marginTop: 10,
      marginRight: 25,
      backgroundColor: theme.palette.primary.main,
      position: "fixed",
      right: 10,
      top: 10,
      zIndex: 999,
    },
    mainLogo: {
      margin: 20,
      position: "fixed",
      left: 10,
      top: 10,
      zIndex: 999,
    },
    subItems: {
      color: "white",
      textDecoration: "none",
      textAlign: "center",
    },
  })
);

type Anchor = "top" | "left" | "bottom" | "right";

export default function AppDraw() {
  const classes = useStyles();
  const classesBase = useStylesBase();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const anchor = "right";

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <IconButton onClick={toggleDrawer(anchor, false)}>
        <CloseRoundedIcon fontSize="large" style={{ color: "white" }} />
      </IconButton>
      <List>
        <ListItem component={Link} to="/" button>
          <Typography variant="h3">Home</Typography>
        </ListItem>
        <ListItem component={Link} to="/about" button>
          <Typography variant="h3">About</Typography>
        </ListItem>
        {/* <ListItem component={Link} to="/team" button>
          <Typography variant="h3">Team</Typography>
        </ListItem> */}
        <ListItem component={Link} to="/ourWork" button>
          <Typography variant="h3">Our Work</Typography>
        </ListItem>
        <ListItem component={Link} to="/contact" button>
          <Typography variant="h3">Contact</Typography>
        </ListItem>
      </List>
      <Grid
        container
        className={classesBase.fullHeight}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid
          item
          xs={6}
          component={Link}
          to="/digitalPhotography"
          className={classes.subItems}
        >
          <Photo />
          <Typography variant="h6">Photography</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          component={Link}
          to="/threeSixty"
          className={classes.subItems}
        >
          <ThreeSixtyIcon />
          <Typography variant="h6">360 Imagery</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          component={Link}
          to="/dronePhotography"
          className={classes.subItems}
        >
          <Drone />
          <Typography variant="h6">Drone Footage</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          component={Link}
          to="/videoProduction"
          className={classes.subItems}
        >
          <Video />
          <Typography variant="h6">Video Production</Typography>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <>
      <Hidden smDown>
        <Link to="/">
          <img src={Logo} alt="logo" className={classes.mainLogo} width="100" />
        </Link>
      </Hidden>
      <Hidden mdUp>
        <Link to="/">
          <img src={MobileLogo} alt="logo" className={classes.mainLogo} />
        </Link>
      </Hidden>

      <IconButton onClick={toggleDrawer(anchor, true)} className={classes.icon}>
        <MenuOpenRoundedIcon fontSize="large" />
      </IconButton>

      <Drawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
      >
        {list(anchor)}
      </Drawer>
    </>
  );
}
