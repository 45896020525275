import { SvgIcon } from "@material-ui/core";
import React from "react";

export default function Communication() {
  return (
    <SvgIcon
      style={{ fontSize: 100 }}
      id="Icon_ionic-ios-chatbubbles"
      data-name="Icon ionic-ios-chatbubbles"
      xmlns="http://www.w3.org/2000/svg"
      width="111.566"
      height="111.566"
      viewBox="0 0 111.566 111.566"
    >
      <path
        id="Path_49"
        data-name="Path 49"
        d="M92.576,76.483a6.5,6.5,0,0,1,.885-3.272,9.033,9.033,0,0,1,.563-.831,43.455,43.455,0,0,0,7.4-24.217c.08-24.727-20.784-44.787-46.584-44.787-22.5,0-41.274,15.313-45.672,35.642a43.1,43.1,0,0,0-.992,9.172,45.493,45.493,0,0,0,45.86,45.35A55.232,55.232,0,0,0,66.7,91.474c3.031-.831,6.034-1.931,6.812-2.226A7.09,7.09,0,0,1,76,88.792a6.973,6.973,0,0,1,2.709.536l15.206,5.391a3.626,3.626,0,0,0,1.046.268,2.135,2.135,0,0,0,2.145-2.145,3.446,3.446,0,0,0-.134-.724Z"
        transform="translate(10.14 -3.375)"
        fill="#ed2228"
      />
      <path
        id="Path_50"
        data-name="Path 50"
        d="M75.92,68.953c-.965.268-2.2.563-3.54.858a49.5,49.5,0,0,1-9.118,1.207A45.493,45.493,0,0,1,17.4,25.668a50.7,50.7,0,0,1,.4-5.739c.161-1.153.349-2.306.617-3.433.268-1.207.59-2.414.939-3.594l-2.145,1.9A39.911,39.911,0,0,0,3.375,44.817a39.466,39.466,0,0,0,6.651,21.991c.617.939.965,1.663.858,2.145S7.693,85.581,7.693,85.581a2.151,2.151,0,0,0,.724,2.065,2.185,2.185,0,0,0,1.368.483,1.924,1.924,0,0,0,.778-.161l15.045-5.927a4.191,4.191,0,0,1,3.218.054A45.137,45.137,0,0,0,45.1,85.313,42.12,42.12,0,0,0,77.314,70.536s.858-1.18,1.85-2.575C78.172,68.31,77.046,68.658,75.92,68.953Z"
        transform="translate(-3.375 23.437)"
        fill="#ed2228"
      />
    </SvgIcon>
  );
}
