import {
  Container,
  createStyles,
  Grid,
  makeStyles,
  Typography,
  Theme,
} from "@material-ui/core";
import React from "react";
import useStylesBase from "../styles/stylesBase";
import main from "../assets/drone/main.jpeg";
import VisualImpact from "../assets/digitalPhotography/VisualImpact";
import Detail from "../assets/digitalPhotography/Detail";
import Brand from "../assets/digitalPhotography/Brand";
import AddedValue from "../assets/digitalPhotography/AddedValue";
import AppPrimaryButton from "../appCompnents/buttons/AppPrimaryButton";

import clientOne from "../assets/drone/pic_one.jpeg";
import clientTwo from "../assets/drone/pic_two.jpeg";
import clientThree from "../assets/drone/pic_three.jpeg";
import clientFour from "../assets/drone/pic_four.jpeg";

import Photo from "../assets/drone/Photo";
import Video from "../assets/drone/Video";
import Qualifications from "../assets/drone/Qualifications";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    coverImage: {
      objectFit: "cover",
      width: "100%",
      height: "110vh",
      opacity: 0.4,
      filter: "alpha(opacity=40)",
      clipPath: "polygon(0 0, 0 100%, 100% 80%, 100% 0)",
    },
    coverText: {
      left: 0,
      position: "absolute",
      textAlign: "center",
      top: "30vh",
      width: "100%",
    },
  })
);

export default function DigitalPhotography() {
  const classes = useStyles();
  const classesBase = useStylesBase();

  return (
    <Grid container>
      <Grid item xs={12}>
        <img src={main} alt="Main background" className={classes.coverImage} />
        <Typography variant="h2" className={classes.coverText}>
          Drone Photography
          <Typography variant="h6">
            Captures that will take you to new hight
          </Typography>
        </Typography>
      </Grid>
      <Container>
        <Grid
          item
          xs={12}
          container
          spacing={5}
          style={{ marginTop: 50 }}
          justify="center"
        >
          <Grid item xs={12} md={4}>
            <div>
              <Photo />
              <Typography variant="h6">Aerial Photography</Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div>
              <Video />
              <Typography variant="h6">Aerial Filming</Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div>
              <Qualifications />
              <Typography variant="h6">CSS Qualified Pilots</Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" align="justify">
              We have been using Drone footage since 2010 and have worked for a
              wide range of clients from small specific bespoke assignments for
              individual clients through to corporates and national broadcasting
              companies including BBC Scrum 5 and the Royal Welsh show, ITV
              coast and country, S4C and Vikings, legacy film production.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classesBase.centerCenter}>
            <div>
              <Typography variant="h5" style={{ margin: "20px 0" }}>
                Want to know how our aerial photography has been implemented?
              </Typography>
              <AppPrimaryButton>View Projects</AppPrimaryButton>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={8} container spacing={3}>
            <Grid item xs={6}>
              <img
                src={clientOne}
                alt="client"
                style={{ height: "50vh", width: "100%", objectFit: "cover" }}
              />
            </Grid>
            <Grid item xs={6}>
              <img
                src={clientTwo}
                alt="client"
                style={{ height: "50vh", width: "100%", objectFit: "cover" }}
              />
            </Grid>
            <Grid item xs={6}>
              <img
                src={clientThree}
                alt="client"
                style={{ height: "50vh", width: "100%", objectFit: "cover" }}
              />
            </Grid>
            <Grid item xs={6}>
              <img
                src={clientFour}
                alt="client"
                style={{ height: "50vh", width: "100%", objectFit: "cover" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
