import { SvgIcon } from "@material-ui/core";
import React from "react";

export default function DroneIcon() {
  return (
    <SvgIcon
      style={{ fontSize: 120 }}
      xmlns="http://www.w3.org/2000/svg"
      width="511"
      height="226.208"
      viewBox="0 0 511 226.208"
    >
      <g id="Group_72" data-name="Group 72" transform="translate(-226.5 -2733)">
        <g
          id="Icon_feather-camera"
          data-name="Icon feather-camera"
          transform="translate(427.163 2864.58)"
        >
          <path
            id="Path_55"
            data-name="Path 55"
            d="M51.835,33.417A19.917,19.917,0,1,1,31.917,13.5,19.917,19.917,0,0,1,51.835,33.417Z"
            transform="translate(22.856 16.376)"
            fill="none"
            stroke="#ed2228"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="10"
          />
        </g>
        <g id="Group_71" data-name="Group 71">
          <path
            id="Path_54"
            data-name="Path 54"
            d="M111.046,84.17a9.959,9.959,0,0,1-9.959,9.959H11.459A9.959,9.959,0,0,1,1.5,84.17V29.4a9.959,9.959,0,0,1,9.959-9.959H31.376L41.335,4.5H71.211L81.17,19.438h19.917a9.959,9.959,0,0,1,9.959,9.959Z"
            transform="translate(425.663 2860.08)"
            fill="none"
            stroke="#ed2228"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="10"
          />
          <g id="drone-svgrepo-com" transform="translate(37.5 2592.972)">
            <path
              id="Path_89"
              data-name="Path 89"
              d="M503.5,144.028H439v-.5a23.5,23.5,0,1,0-47,0v.5H327.5a7.5,7.5,0,0,0,0,15H384v8.5c0,.168.01.333.013.5H126.987c0-.167.013-.332.013-.5v-8.5h56.5a7.5,7.5,0,0,0,0-15H119v-.5a23.5,23.5,0,0,0-47,0v.5H7.5a7.5,7.5,0,0,0,0,15H64v8.5a31.49,31.49,0,0,0,58.906,15.5h47.188l7,20.991a39.449,39.449,0,0,0,37.473,27.009H248v18.234c-9.29,3.138,24.29,3.138,15,0V231.028h33.436a39.449,39.449,0,0,0,37.473-27.009l7-20.991h47.188A31.49,31.49,0,0,0,447,167.528v-8.5h56.5a7.5,7.5,0,1,0,0-15Zm-416.5-.5a8.5,8.5,0,0,1,17,0v.5H87Zm8.5,40.5a16.519,16.519,0,0,1-16.5-16.5v-8.5h33v8.5A16.519,16.519,0,0,1,95.5,184.028Zm160,79Zm64.178-63.752a24.468,24.468,0,0,1-23.243,16.752H214.564a24.468,24.468,0,0,1-23.243-16.752L185.9,183.028H325.093ZM407,143.528a8.5,8.5,0,0,1,17,0v.5H407Zm25,24a16.5,16.5,0,0,1-33,0v-8.5h33Z"
              transform="translate(189 20)"
              fill="#ed2228"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
