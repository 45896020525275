import { SvgIcon } from "@material-ui/core";
import React from "react";

export default function CustomTours() {
  return (
    <SvgIcon
      style={{ fontSize: 100 }}
      xmlns="http://www.w3.org/2000/svg"
      width="98"
      height="133.333"
      viewBox="0 0 98 133.333"
    >
      <g id="Group_31" data-name="Group 31" transform="translate(-1509 -1495)">
        <path
          id="Icon_awesome-vr-cardboard"
          data-name="Icon awesome-vr-cardboard"
          d="M93.1,4.5H4.9A5.1,5.1,0,0,0,0,9.777v52.77a5.1,5.1,0,0,0,4.9,5.277H29.434A9.79,9.79,0,0,0,38.37,61.6l4.248-10.165A7.124,7.124,0,0,1,49,46.716a7.124,7.124,0,0,1,6.382,4.72L59.63,61.6a9.791,9.791,0,0,0,8.936,6.223H93.1A5.1,5.1,0,0,0,98,62.546V9.777A5.1,5.1,0,0,0,93.1,4.5ZM24.5,44.077c-5.413,0-9.8-4.725-9.8-10.554s4.387-10.554,9.8-10.554,9.8,4.725,9.8,10.554S29.913,44.077,24.5,44.077Zm49,0c-5.413,0-9.8-4.725-9.8-10.554s4.387-10.554,9.8-10.554,9.8,4.725,9.8,10.554S78.913,44.077,73.5,44.077Z"
          transform="translate(1509 1560.51)"
          fill="#ed2228"
        />
        <path
          id="Icon_awesome-pencil-ruler"
          data-name="Icon awesome-pencil-ruler"
          d="M13.087,29.177l16.09-16.087L23.9,7.815l-7.374,7.374a.947.947,0,0,1-1.34,0l-1.34-1.34a.947.947,0,0,1,0-1.34l7.374-7.374L17.2,1.111a3.792,3.792,0,0,0-5.363,0L1.111,11.836a3.793,3.793,0,0,0,0,5.363L13.087,29.177ZM59.53,15.213a5.743,5.743,0,0,0,0-8.121l-5.41-5.41a5.745,5.745,0,0,0-8.124,0l-5.5,5.5L54.028,20.716ZM37.789,9.889,2.281,45.392.039,58.237a2.538,2.538,0,0,0,2.94,2.936l12.846-2.252,35.5-35.5ZM60.1,44.014,56.08,39.992l-7.374,7.374a.947.947,0,0,1-1.34,0l-1.34-1.34a.949.949,0,0,1,0-1.34L53.4,37.311l-5.277-5.277L32.033,48.121,44.013,60.1a3.792,3.792,0,0,0,5.363,0L60.1,49.377a3.792,3.792,0,0,0,0-5.363Z"
          transform="translate(1526.868 1495.001)"
          fill="#ed2228"
        />
      </g>
    </SvgIcon>
  );
}
