import { SvgIcon } from "@material-ui/core";
import React from "react";

export default function Brand() {
  return (
    <SvgIcon
      style={{ fontSize: 100 }}
      id="label-24px"
      xmlns="http://www.w3.org/2000/svg"
      width="166"
      height="90"
      viewBox="0 0 166 90"
    >
      <path id="Path_45" data-name="Path 45" d="M0,0H166V90H0Z" fill="none" />
      <path
        id="Path_46"
        data-name="Path 46"
        d="M118.216,9.53C115.381,6.779,110.656,5,105.379,5l-86.629.054C10.088,5.054,3,9.853,3,15.785V69.708c0,5.932,7.088,10.731,15.751,10.731l86.629.054c5.276,0,10-1.779,12.837-4.53L147.57,42.746l-1.378-1.56L118.216,9.53ZM105.379,69.708H11.585V15.785h93.794l28.906,26.962Z"
        transform="translate(18.43 3.69)"
        fill="#ed2228"
      />
      <text
        id="BRAND"
        transform="translate(83.814 56.667)"
        fill="#ed2228"
        font-size="27"
        font-family="MicrosoftJhengHeiBold, Microsoft JhengHei"
      >
        <tspan x="-48.931" y="0">
          BRAND
        </tspan>
      </text>
    </SvgIcon>
  );
}
