import {
  Container,
  createStyles,
  Grid,
  makeStyles,
  Typography,
  Theme,
  Hidden,
} from "@material-ui/core";
import React from "react";
import useStylesBase from "../styles/stylesBase";
import main from "../assets/digitalPhotography/main.jpeg";
import VisualImpact from "../assets/digitalPhotography/VisualImpact";
import Detail from "../assets/digitalPhotography/Detail";
import Brand from "../assets/digitalPhotography/Brand";
import AddedValue from "../assets/digitalPhotography/AddedValue";
import AppPrimaryButton from "../appCompnents/buttons/AppPrimaryButton";
import ReactPlayer from "react-player";
// @ts-ignore
import HoverVideoPlayer from "react-hover-video-player";
import imageOne from "../assets/videoProduciton/vid1.png";
import imageTwo from "../assets/videoProduciton/vid2.png";
import Communication from "../assets/videoProduciton/Communication";
import Engagement from "../assets/videoProduciton/Engagement";
import Sco from "../assets/videoProduciton/Sco";
import ReturnOnInvestment from "../assets/videoProduciton/ReturnOnInvestment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    coverImage: {
      objectFit: "cover",
      width: "100%",
      height: "110vh",
      opacity: 0.4,
      filter: "alpha(opacity=40)",
      clipPath: "polygon(0 0, 0 100%, 100% 80%, 100% 0)",
    },
    coverText: {
      left: 0,
      position: "absolute",
      textAlign: "center",
      top: "30vh",
      width: "100%",
    },
  })
);

export default function VideoProduction() {
  const classes = useStyles();
  const classesBase = useStylesBase();

  return (
    <Grid container>
      <Grid item xs={12}>
        <img src={main} alt="Main background" className={classes.coverImage} />
        <Typography variant="h2" className={classes.coverText}>
          Video Production
          <Typography variant="h6">
            Inspire your audience with action
          </Typography>
        </Typography>
      </Grid>
      <Container>
        <Grid item xs={12} container spacing={5} style={{ marginTop: 50 }}>
          <Grid item xs={6} md={3}>
            <div>
              <Communication />
              <Typography variant="h6">Key Combination</Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={3}>
            <div>
              <Engagement />
              <Typography variant="h6">Improved Engagement</Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={3}>
            <div>
              <Sco />
              <Typography variant="h6">Enhanced SCO</Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={3}>
            <div>
              <ReturnOnInvestment />
              <Typography variant="h6">Higher ROI</Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" align="justify">
              We cover all aspects of video production. We have been involved in
              documentary film making, university and academic campaigns,
              marketing and live stream broad casts. Our skills have developed
              from 2003 filming extreme events, off roading sporting events
              which cover pre production and pre recorded but also live
              broadcasting. Filming has been in various international locations
              for wildlife and cultural wonders. Our team are all full qualified
              and experienced to ensure that we deliver high quality video
              production any where in the world.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={8} container spacing={3}>
            <ReactPlayer
              url="https://www.youtube.com/watch?v=PgFbrhXjybE"
              playing
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classesBase.centerCenter}>
            <div>
              <Typography variant="h5" style={{ margin: "20px 0" }}>
                Want to know how our digital photography has been implemented?
              </Typography>
              <AppPrimaryButton to="/ourWork">View Projects</AppPrimaryButton>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
