import {
  Container,
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";

import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import ArrowLogo from "../assets/footer/ArrowLogo";
import AppSecondaryButton from "../appCompnents/buttons/AppSecondaryButton";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  wrapper: {
    marginTop: 50,
  },
});

export default function Footer() {
  const classes = useStyles();

  return (
    <Container>
      <Grid container spacing={3} className={classes.wrapper}>
        <Grid item xs={12}>
          <ArrowLogo />
          <Typography variant="h1"> Stay Connected</Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12}>
            <Typography variant="h2">
              Be the first to hear about our latest work.
            </Typography>
          </Grid>
        </Hidden>
        <Grid item xs={12}>
          <Typography variant="h3">Follow us on social media</Typography>
        </Grid>
        <Grid item xs={4}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/LoveInDigital/"
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            <FacebookIcon fontSize="large" />
            <Hidden xsDown>
              <Typography variant="h5">Facebook</Typography>
            </Hidden>
          </a>
        </Grid>
        <Grid item xs={4}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/company/love-indigital"
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            <LinkedInIcon fontSize="large" />
            <Hidden xsDown>
              <Typography variant="h5">LinkedIn</Typography>
            </Hidden>
          </a>
        </Grid>
        <Grid item xs={4}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/love_indigital/"
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            <InstagramIcon fontSize="large" />
            <Hidden xsDown>
              <Typography variant="h5">Instagram</Typography>
            </Hidden>
          </a>
        </Grid>
        <Grid item xs={4} sm={3}>
          <AppSecondaryButton to="/">Home</AppSecondaryButton>
        </Grid>
        <Grid item xs={4} sm={3}>
          <AppSecondaryButton to="/about">About</AppSecondaryButton>
        </Grid>
        <Grid item xs={4} sm={3}>
          <AppSecondaryButton to="/ourWork">Our Work</AppSecondaryButton>
        </Grid>
        {/* <Grid item xs={4} sm={2}>
          <AppSecondaryButton to="/team">Team</AppSecondaryButton>
        </Grid> */}
        <Grid item xs={4} sm={3}>
          <AppSecondaryButton to="/contact">Contact</AppSecondaryButton>
        </Grid>
        {/* <Grid item xs={4} sm={2}>
            <AppSecondaryButton to="/privacyPolicy">
              Privacy Policy
            </AppSecondaryButton>
          </Grid> */}
        <Grid item xs={12}>
          <Typography variant="body1">
            © 2023 Love Property Group Ltd.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
