import React from "react";

export default function ArrowLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="75.355"
      height="55.355"
      viewBox="0 0 75.355 55.355"
    >
      <g
        id="Group_70"
        data-name="Group 70"
        transform="translate(-635.529 -2906.529)"
      >
        <rect
          id="Rectangle_41"
          data-name="Rectangle 41"
          width="25"
          height="25"
          transform="translate(653.207 2906.529) rotate(45)"
          fill="#ed2228"
        />
        <rect
          id="Rectangle_42"
          data-name="Rectangle 42"
          width="25"
          height="25"
          transform="translate(673.207 2926.529) rotate(45)"
          fill="#ed2228"
        />
        <rect
          id="Rectangle_44"
          data-name="Rectangle 44"
          width="25"
          height="25"
          transform="translate(693.207 2906.529) rotate(45)"
          fill="#ed2228"
        />
      </g>
    </svg>
  );
}
