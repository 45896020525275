import {
  Container,
  createStyles,
  Grid,
  makeStyles,
  Typography,
  Theme,
  Hidden,
} from "@material-ui/core";
import React from "react";
import useStylesBase from "../styles/stylesBase";
import main from "../assets/threeSixty/main.jpeg";
import Photo from "../assets/threeSixty/Photo";
import Video from "../assets/threeSixty/Video";
import CustomTours from "../assets/threeSixty/CustomTours";
import Iframe from "react-iframe";

import AppPrimaryButton from "../appCompnents/buttons/AppPrimaryButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    coverImage: {
      objectFit: "cover",
      width: "100%",
      height: "110vh",
      opacity: 0.4,
      filter: "alpha(opacity=40)",
      clipPath: "polygon(0 0, 0 100%, 100% 80%, 100% 0)",
    },
    coverText: {
      left: 0,
      position: "absolute",
      textAlign: "center",
      top: "30vh",
      width: "100%",
    },
    iframe: {
      border: "none",
      borderRadius: "10px",
      width: "100%",
      height: "80vh",
    },
  })
);

export default function ThreeSixtyPhotography() {
  const classes = useStyles();
  const classesBase = useStylesBase();

  return (
    <Grid container>
      <Grid item xs={12}>
        <img src={main} alt="Main background" className={classes.coverImage} />
        <Typography variant="h2" className={classes.coverText}>
          360° Photography
        </Typography>
      </Grid>
      <Container>
        <Grid item xs={12} container spacing={5} style={{ marginTop: 50 }}>
          <Grid item xs={12} sm={6} md={4}>
            <div>
              <Photo />
              <Typography variant="h6">360° Photography</Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div>
              <Video />
              <Typography variant="h6">360° Filming</Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div>
              <CustomTours />
              <Typography variant="h6">Custom Virtual Tours</Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" align="justify">
              We have been using 360 technology since the early inception in
              2008 and have progressed as the technology has developed. This
              includes customised virtual tours, property tours and commercial
              immersive virtual spaces. With the advance in technologies and the
              combined use of 360 cameras and drones we have recently delivered
              a virtual 360 “Harry Potter virtual experience”.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={12}
            className={classesBase.centerCenter}
          >
            <div>
              <Typography variant="h5" style={{ margin: "20px 0" }}>
                Want to know how our virtual tours have been implemented?
              </Typography>
              <AppPrimaryButton to="/ourWork">View Projects</AppPrimaryButton>
            </div>
          </Grid>

          <Grid item xs={12} container spacing={3}>
            <Iframe
              url="https://pandfestates.digitise.tours/"
              position="relative"
              className={classes.iframe}
            />
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
