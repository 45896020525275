import React from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  fade,
  darken,
} from "@material-ui/core/styles";
import { Button, SvgIconProps } from "@material-ui/core";
import { Link } from "react-router-dom";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderRadius: 15,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.secondary,
      height: 50,
      padding: "0 20px",
      "&:hover": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light,
      },
    },
  })
);

interface AppPrimaryButtonProps {
  className?: string;
  icon?: React.ReactElement<SvgIconProps>;
  type?: "button" | "submit" | "reset" | undefined;
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  to?: any;
  children?: React.ReactNode;
}

export default function AppPrimaryButton({
  className,
  icon,
  type,
  onClick,
  to,
  children,
}: AppPrimaryButtonProps) {
  const classes = useStyles();

  return (
    <Button
      disableElevation
      type={type}
      fullWidth
      variant="contained"
      className={clsx(classes.button, className)}
      startIcon={icon}
      onClick={onClick}
      to={to}
      component={to ? Link : "button"}
    >
      {children}
    </Button>
  );
}
