import { SvgIcon } from "@material-ui/core";
import React from "react";

export default function Qualifications() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="232.918"
      height="142"
      viewBox="0 0 232.918 142"
      style={{ fontSize: 100 }}
    >
      <g id="Group_73" data-name="Group 73" transform="translate(-260 -2900)">
        <g id="Group_72" data-name="Group 72" transform="translate(127.5 167)">
          <g
            id="Group_71"
            data-name="Group 71"
            transform="translate(226.5 2733)"
          >
            <g id="drone-svgrepo-com" transform="translate(0 0)">
              <path
                id="Path_89"
                data-name="Path 89"
                d="M136.879,126.553H119.345v-.136a6.389,6.389,0,0,0-12.777,0v.136H89.033a2.039,2.039,0,1,0,0,4.078h15.36v2.311c0,.046,0,.091,0,.136H34.522c0-.045,0-.09,0-.136V130.63h15.36a2.039,2.039,0,1,0,0-4.078H32.351v-.136a6.389,6.389,0,0,0-12.777,0v.136H2.039a2.039,2.039,0,1,0,0,4.078H17.4v2.311a8.561,8.561,0,0,0,16.014,4.214H46.241l1.9,5.707A10.725,10.725,0,0,0,58.33,150.2h9.09v4.957c-2.526.853,6.6.853,4.078,0V150.2h9.09a10.725,10.725,0,0,0,10.187-7.343l1.9-5.707h12.828a8.561,8.561,0,0,0,16.014-4.214V130.63h15.36a2.039,2.039,0,0,0,0-4.078Zm-113.228-.136a2.311,2.311,0,0,1,4.622,0v.136H23.651Zm2.311,11.01a4.491,4.491,0,0,1-4.486-4.486V130.63h8.971v2.311A4.491,4.491,0,0,1,25.962,137.427Zm43.5,21.477Zm17.447-17.331a6.652,6.652,0,0,1-6.319,4.554H58.33a6.652,6.652,0,0,1-6.319-4.554l-1.472-4.417H88.378Zm23.739-15.155a2.311,2.311,0,1,1,4.622,0v.136h-4.622Zm6.8,6.525a4.486,4.486,0,0,1-8.971,0V130.63h8.971Z"
                transform="translate(0 -120.028)"
                fill="#ed2228"
              />
            </g>
          </g>
        </g>
        <g id="engineering-24px" transform="translate(260.333 2900)">
          <g id="Group_9" data-name="Group 9" transform="translate(-0.333)">
            <rect
              id="Rectangle_31"
              data-name="Rectangle 31"
              width="115"
              height="142"
              transform="translate(0)"
              fill="none"
            />
          </g>
          <g
            id="Group_11"
            data-name="Group 11"
            transform="translate(6.664 9.839)"
          >
            <g id="Group_10" data-name="Group 10">
              <path
                id="Path_59"
                data-name="Path 59"
                d="M53.924,15C36.261,15,1,23.865,1,41.462V54.693H106.849V41.462C106.849,23.865,71.588,15,53.924,15Z"
                transform="translate(-1 57.771)"
                fill="#ed2228"
              />
              <path
                id="Path_62"
                data-name="Path 62"
                d="M7.492,37.078H63.922a3.247,3.247,0,0,0,3.242-3.242V33.7a3.247,3.247,0,0,0-3.242-3.242H62.136A26.441,26.441,0,0,0,48.9,7.639v6.285a3.308,3.308,0,1,1-6.616,0v-9A27.062,27.062,0,0,0,35.674,4a27.062,27.062,0,0,0-6.616.926v9a3.308,3.308,0,1,1-6.616,0V7.639A26.441,26.441,0,0,0,9.212,30.462H7.492A3.247,3.247,0,0,0,4.25,33.7v.2A3.234,3.234,0,0,0,7.492,37.078Z"
                transform="translate(17.251 -4)"
                fill="#ed2228"
              />
              <path
                id="Path_63"
                data-name="Path 63"
                d="M30.676,29.847A26.461,26.461,0,0,0,56.212,10H5.14A26.461,26.461,0,0,0,30.676,29.847Z"
                transform="translate(22.248 29.693)"
                fill="#ed2228"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
