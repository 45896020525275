import classes from "*.module.css";
import { Container, Grid, Typography } from "@material-ui/core";
import React from "react";
import useStylesBase from "../styles/stylesBase";

import {
  main,
  photography,
  threeSixty,
  drone,
  video,
  richmondSquare,
  empire,
  baronsCourt,
  cathedralPark,
} from "../assets/home";
import AppSecondaryButton from "../appCompnents/buttons/AppSecondaryButton";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import clsx from "clsx";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import DownArrow from "../layout/DownArrow";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export default function Home() {
  const classesBase = useStylesBase();
  return (
    <Grid container>
      <Grid item xs={12}>
        <img
          src={main}
          alt="Main background"
          className={classesBase.imageHeader}
        />

        <DownArrow />
      </Grid>
      <Grid item xs={1} md={2} />
      <Grid item xs={10} md={8} style={{ margin: "50px 0" }}>
        <Typography variant="h2" align="center">
          At Love InDigital, we create social media and digital marketing
          content. This includes 360° virtual tours, 360 images, drone 360,
          drone cinematic videos, and drone photography
        </Typography>
      </Grid>
      <Grid item xs={1} md={2} />

      <Container>
        <Grid item xs={12} container spacing={3} style={{ margin: "50px 0" }}>
          <Grid item xs={6} md={3}>
            <img
              src={photography}
              alt="Photography"
              className={classesBase.image}
            />

            <AppSecondaryButton
              to="/digitalPhotography"
              icon={<ArrowForwardRoundedIcon />}
            >
              Photography
            </AppSecondaryButton>
          </Grid>
          <Grid item xs={6} md={3}>
            <img
              src={threeSixty}
              alt="360 Photography"
              className={classesBase.image}
            />
            <AppSecondaryButton
              to="/threeSixty"
              icon={<ArrowForwardRoundedIcon />}
            >
              360° Photography
            </AppSecondaryButton>
          </Grid>
          <Grid item xs={6} md={3}>
            <img src={drone} alt="Drone" className={classesBase.image} />
            <AppSecondaryButton
              to="/dronePhotography"
              icon={<ArrowForwardRoundedIcon />}
            >
              Drone
            </AppSecondaryButton>
          </Grid>
          <Grid item xs={6} md={3}>
            <img src={video} alt="Video" className={classesBase.image} />
            <AppSecondaryButton
              to="/videoProduction"
              icon={<ArrowForwardRoundedIcon />}
            >
              Video
            </AppSecondaryButton>
          </Grid>
          <Grid item xs={12} style={{ margin: "50px 0" }}>
            <Typography variant="h2" style={{ margin: "50px 0" }}>
              Clients
            </Typography>
            <Carousel
              swipeable={true}
              draggable={false}
              showDots={true}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={5000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={1000}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-10-px"
            >
              <div
                className={clsx(
                  classesBase.centerCenter,
                  classesBase.fullHeight
                )}
              >
                <img
                  src={richmondSquare}
                  alt="richmond square logo"
                  style={{ backgroundColor: "#b3139f", borderRadius: 10 }}
                  width="80%"
                />
              </div>
              <div
                className={clsx(
                  classesBase.centerCenter,
                  classesBase.fullHeight
                )}
              >
                <img
                  src={empire}
                  alt="empire logo"
                  style={{
                    backgroundColor: "#1c1c38",
                    borderRadius: 10,
                    margin: 20,
                  }}
                  width="80%"
                />
              </div>
              <div
                className={clsx(
                  classesBase.centerCenter,
                  classesBase.fullHeight
                )}
              >
                <img
                  src={baronsCourt}
                  alt="barons court logo"
                  style={{
                    backgroundColor: "#42710f",
                    borderRadius: 10,
                    padding: 20,
                  }}
                  width="50%"
                />
              </div>
              <div
                className={clsx(
                  classesBase.centerCenter,
                  classesBase.fullHeight
                )}
              >
                <img
                  src={cathedralPark}
                  alt="cathedral park logo"
                  style={{ backgroundColor: "#b79a0d", borderRadius: 10 }}
                  width="80%"
                />
              </div>
            </Carousel>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
