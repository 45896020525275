import { SvgIcon } from "@material-ui/core";
import React from "react";

export default function VisualImpact() {
  return (
    <SvgIcon
      style={{ fontSize: 100 }}
      id="camera-24px"
      xmlns="http://www.w3.org/2000/svg"
      width="121"
      height="121"
      viewBox="0 0 121 121"
    >
      <path id="Path_40" data-name="Path 40" d="M0,0H121V121H0Z" fill="none" />
      <path
        id="Path_41"
        data-name="Path 41"
        d="M39.308,44.854,63.357,3.21A51.957,51.957,0,0,0,52.417,2,50.337,50.337,0,0,0,20.553,13.344L39.006,45.358l.3-.5Zm61.206-7.562A50.542,50.542,0,0,0,70.264,5.327L51.812,37.292Zm1.311,5.042H64.063l1.462,2.521,24,41.594a49.966,49.966,0,0,0,13.31-34.031,51.041,51.041,0,0,0-1.008-10.083ZM34.972,52.417,15.31,18.385A50.2,50.2,0,0,0,2,52.417,51.041,51.041,0,0,0,3.008,62.5H40.77ZM4.319,67.542a50.542,50.542,0,0,0,30.25,31.964L53.022,67.542Zm56.82,0L41.476,101.623a51.957,51.957,0,0,0,10.94,1.21A50.337,50.337,0,0,0,84.28,91.49L65.827,59.475Z"
        transform="translate(8.083 8.083)"
        fill="#ed2228"
      />
    </SvgIcon>
  );
}
