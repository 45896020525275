import {
  Container,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Typography,
  Theme,
  Paper,
  Hidden,
} from "@material-ui/core";
import AppSecondaryButton from "../appCompnents/buttons/AppSecondaryButton";

export default function Contact({ fullWidth }: any) {
  return (
    <div>
      <Container maxWidth={"md"} style={{ paddingTop: fullWidth ? 80 : 150 }}>
        <Grid container style={{ marginTop: 10, zIndex: 1 }}>
          <form name="contact" method="post">
            <input type="hidden" name="form-name" value="contact" />
            <Paper
              style={{
                zIndex: 99,
                padding: "10px 40px",
                // border: "4px solid #ed2228",
                backgroundColor: "#f6f6f6",
                borderRadius: 20,
                maxWidth: "1200px",
              }}
            >
              <Grid item xs={12} style={{ margin: "40px 0" }}>
                <Typography variant="h5">Get In Touch</Typography>
              </Grid>
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={12} md={6} container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      label="Name *"
                      name="name"
                      fullWidth
                      style={{ color: "black" }}
                      variant="outlined"
                      focused
                    ></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Email *"
                      name="email"
                      type="email"
                      fullWidth
                      style={{ color: "black" }}
                      variant="outlined"
                      focused
                    ></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Company"
                      name="company"
                      fullWidth
                      style={{ color: "black" }}
                      variant="outlined"
                      focused
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} container>
                  <TextField
                    label="Message *"
                    multiline
                    name="message"
                    rows={10}
                    fullWidth
                    style={{ color: "black" }}
                    variant="outlined"
                    focused
                  ></TextField>
                </Grid>
                <Grid item xs={12} style={{ margin: "20px 0" }}>
                  <AppSecondaryButton type="submit">Submit</AppSecondaryButton>
                </Grid>
              </Grid>
            </Paper>
          </form>
        </Grid>
      </Container>
    </div>
  );
}
