import { SvgIcon } from "@material-ui/core";
import React from "react";

export default function AddedValue() {
  return (
    <SvgIcon
      id="Group_5"
      style={{ fontSize: 100 }}
      data-name="Group 5"
      xmlns="http://www.w3.org/2000/svg"
      width="115.03"
      height="102"
      viewBox="0 0 115.03 102"
    >
      <path
        id="Icon_awesome-hand-holding-usd"
        data-name="Icon awesome-hand-holding-usd"
        d="M112.9,53.014a6.35,6.35,0,0,0-8.522,0L85.935,68.641a12.3,12.3,0,0,1-7.993,2.96H54.323a3.389,3.389,0,0,1,0-6.767H69.949A6.772,6.772,0,0,0,70.287,51.3H38.337a22.609,22.609,0,0,0-14.781,5.561l-9.283,7.972H3.193A3.3,3.3,0,0,0,0,68.218v20.3A3.3,3.3,0,0,0,3.193,91.9H74.432a12.382,12.382,0,0,0,7.993-2.96l30.2-25.586a7.026,7.026,0,0,0,.275-10.34Z"
        transform="translate(0 10.099)"
        fill="#ed2228"
      />
      <path
        id="Icon_awesome-pound-sign"
        data-name="Icon awesome-pound-sign"
        d="M33.4,36.951H28.466a1.3,1.3,0,0,0-1.3,1.3v5.514H13.881V30.011H22.99a1.3,1.3,0,0,0,1.3-1.3V24.372a1.3,1.3,0,0,0-1.3-1.3H13.881V16.179c0-3.5,2.664-6.19,6.7-6.19a12.527,12.527,0,0,1,6.252,2.044,1.3,1.3,0,0,0,1.7-.291l3.09-3.851a1.3,1.3,0,0,0-.23-1.852A18.636,18.636,0,0,0,20.38,2.25C11.5,2.25,5.205,7.969,5.205,15.909v7.161H2.169a1.3,1.3,0,0,0-1.3,1.3V28.71a1.3,1.3,0,0,0,1.3,1.3H5.205V43.892H1.3a1.3,1.3,0,0,0-1.3,1.3v4.338a1.3,1.3,0,0,0,1.3,1.3H33.4a1.3,1.3,0,0,0,1.3-1.3V38.253A1.3,1.3,0,0,0,33.4,36.951Z"
        transform="translate(42.207 -2.25)"
        fill="#ed2228"
      />
    </SvgIcon>
  );
}
