import { createMuiTheme, Theme } from "@material-ui/core";

const AppTheme: Theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ED2228",
      light: "#f7f7f7",
      dark: "#564d4a",
    },
    background: {
      default: "#f7f4f3",
      paper: "#ED2228",
    },
    text: {
      primary: "#000",
      secondary: "#fff",
    },
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: "4rem",
      },
      h2: {
        fontSize: "2.75rem",
      },
      h3: {
        fontSize: "2rem",
      },
    },
  },
});

export default AppTheme;
