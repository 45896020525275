import {
  Container,
  createStyles,
  Grid,
  makeStyles,
  Typography,
  Theme,
  Hidden,
} from "@material-ui/core";
import React from "react";
import useStylesBase from "../styles/stylesBase";
import main from "../assets/digitalPhotography/main.jpeg";
import VisualImpact from "../assets/digitalPhotography/VisualImpact";
import Detail from "../assets/digitalPhotography/Detail";
import Brand from "../assets/digitalPhotography/Brand";
import AddedValue from "../assets/digitalPhotography/AddedValue";
import AppPrimaryButton from "../appCompnents/buttons/AppPrimaryButton";

import clientOne from "../assets/digitalPhotography/client_one.jpg";
import clientTwo from "../assets/digitalPhotography/client_two.jpg";
import clientThree from "../assets/digitalPhotography/client_three.jpg";
import clientFour from "../assets/digitalPhotography/client_four.jpg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    coverImage: {
      objectFit: "cover",
      width: "100%",
      height: "110vh",
      opacity: 0.4,
      filter: "alpha(opacity=40)",
      clipPath: "polygon(0 0, 0 100%, 100% 80%, 100% 0)",
    },
    coverText: {
      left: 0,
      position: "absolute",
      textAlign: "center",
      top: "30vh",
      width: "100%",
    },
  })
);

export default function DigitalPhotography() {
  const classes = useStyles();
  const classesBase = useStylesBase();

  return (
    <Grid container>
      <Grid item xs={12}>
        <img src={main} alt="Main background" className={classes.coverImage} />
        <Typography variant="h2" className={classes.coverText}>
          Digital Commercial Photography
          <Typography variant="h6">
            Creating exposure for you proteins is our passion
          </Typography>
        </Typography>
      </Grid>
      <Container>
        <Grid item xs={12} container spacing={5} style={{ marginTop: 50 }}>
          <Grid item xs={6} md={3}>
            <div>
              <VisualImpact />
              <Typography variant="h6">Visual Impact</Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={3}>
            <div>
              <Detail />
              <Typography variant="h6">Attention to Detail</Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={3}>
            <div>
              <Brand />
              <Typography variant="h6">Brand Awareness</Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={3}>
            <div>
              <AddedValue />
              <Typography variant="h6">Added Value</Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" align="justify">
              We have vast experience ranging from weddings, portrait, landscape
              and property photography. We work closely with our clients for
              their specific requirements We are one of the leaders in drone
              photography since there inception since 2014.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classesBase.centerCenter}>
            <div>
              <Typography variant="h6" style={{ margin: "20px 0" }}>
                Want to know how our digital photography has been implemented?
              </Typography>
              <AppPrimaryButton to="/ourWork">View Projects</AppPrimaryButton>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={8} container spacing={3}>
            <Grid item xs={6}>
              <img
                src={clientOne}
                alt="client"
                style={{ height: "50vh", width: "100%", objectFit: "cover" }}
              />
            </Grid>
            <Grid item xs={6}>
              <img
                src={clientTwo}
                alt="client"
                style={{ height: "50vh", width: "100%", objectFit: "cover" }}
              />
            </Grid>
            <Grid item xs={6}>
              <img
                src={clientThree}
                alt="client"
                style={{ height: "50vh", width: "100%", objectFit: "cover" }}
              />
            </Grid>
            <Grid item xs={6}>
              <img
                src={clientFour}
                alt="client"
                style={{ height: "50vh", width: "100%", objectFit: "cover" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
