import { SvgIcon } from "@material-ui/core";
import React from "react";

export default function ReturnOnInvestment() {
  return (
    <SvgIcon
      style={{ fontSize: 100 }}
      xmlns="http://www.w3.org/2000/svg"
      width="112.768"
      height="110.832"
      viewBox="0 0 112.768 110.832"
    >
      <g id="Group_71" data-name="Group 71" transform="translate(0 0)">
        <g
          id="Ellipse_5"
          data-name="Ellipse 5"
          transform="translate(12.187 110.832) rotate(-97)"
          fill="none"
          stroke="#ed2228"
          stroke-linecap="round"
          stroke-width="8"
          stroke-dasharray="141 35"
        >
          <ellipse cx="50" cy="47.5" rx="50" ry="47.5" stroke="none" />
          <ellipse cx="50" cy="47.5" rx="46" ry="43.5" fill="none" />
        </g>
        <g
          id="Polygon_1"
          data-name="Polygon 1"
          transform="translate(87.774 46.759) rotate(16)"
          fill="#ed2228"
        >
          <path
            d="M 20.74266052246094 21.49999809265137 L 5.257339954376221 21.49999809265137 C 4.348259925842285 21.49999809265137 3.536550045013428 21.02861785888672 3.085989952087402 20.23904800415039 C 2.635430097579956 19.44946670532227 2.642549991607666 18.51082801818848 3.105020046234131 17.72816848754883 L 10.84768009185791 4.625217437744141 C 11.30212020874023 3.856177568435669 12.10671997070313 3.397047519683838 13 3.397047519683838 C 13.89326953887939 3.397047519683838 14.69787979125977 3.856177568435669 15.15231990814209 4.625227451324463 L 22.89496994018555 17.72816848754883 C 23.35744094848633 18.51082801818848 23.36455917358398 19.44946670532227 22.91400909423828 20.23904800415039 C 22.46346092224121 21.02861785888672 21.6517391204834 21.49999809265137 20.74266052246094 21.49999809265137 Z"
            stroke="none"
          />
          <path
            d="M 13 3.89704704284668 C 12.28538036346436 3.89704704284668 11.64169025421143 4.264347076416016 11.27814960479736 4.879587173461914 L 3.535490036010742 17.9825382232666 C 3.165510177612305 18.60865783691406 3.159820556640625 19.35957717895508 3.520259857177734 19.99123764038086 C 3.880699157714844 20.62288665771484 4.530080795288086 20.99999809265137 5.257339477539063 20.99999809265137 L 20.74266052246094 20.99999809265137 C 21.46992111206055 20.99999809265137 22.11930084228516 20.62288665771484 22.47974014282227 19.99123764038086 C 22.84017944335938 19.35957717895508 22.8344898223877 18.60865783691406 22.46450042724609 17.9825382232666 L 14.7218599319458 4.879587173461914 C 14.35830020904541 4.26435661315918 13.71461963653564 3.89704704284668 13 3.89704704284668 M 12.99999618530273 2.89704704284668 C 14.00109004974365 2.89704704284668 15.00218486785889 3.388317108154297 15.58277988433838 4.370857238769531 L 23.32542991638184 17.47380828857422 C 24.50716018676758 19.47365760803223 23.06555938720703 21.99999809265137 20.74266052246094 21.99999809265137 L 5.257339477539063 21.99999809265137 C 2.934440612792969 21.99999809265137 1.492839813232422 19.47365760803223 2.674560546875 17.47380828857422 L 10.41722011566162 4.370857238769531 C 10.99781036376953 3.388317108154297 11.99890232086182 2.89704704284668 12.99999618530273 2.89704704284668 Z"
            stroke="none"
            fill="#ed2228"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
