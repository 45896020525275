import { Container, Grid, Typography } from "@material-ui/core";
import React from "react";
import AppSecondaryButton from "../appCompnents/buttons/AppSecondaryButton";
import { threeSixty, photography, video, drone } from "../assets/about";
import CameraIcon from "../assets/about/CameraIcon";
import DroneIcon from "../assets/about/DroneIcon";
import ThreeSixtyIcon from "../assets/about/ThreeSixtyIcon";
import VideoIcon from "../assets/about/VideoIcon";
import useStylesBase from "../styles/stylesBase";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";

import { Link } from "react-router-dom";

import clientOne from "../assets/digitalPhotography/client_one.jpg";
import clientTwo from "../assets/digitalPhotography/client_two.jpg";
import clientThree from "../assets/digitalPhotography/client_three.jpg";
import clientFour from "../assets/digitalPhotography/client_four.jpg";

export default function OurWork() {
  const classesBase = useStylesBase();

  return (
    <Container>
      <Grid container>
        <Grid item xs={12} className={classesBase.header}>
          <Typography variant="h1">Our work</Typography>
        </Grid>
        <Grid xs={12} container spacing={3}>
          <Grid item xs={12} sm={6}>
            <img
              src={clientOne}
              alt="client"
              style={{
                height: "50vh",
                width: "100%",
                objectFit: "cover",
                borderRadius: 10,
              }}
            />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://youtu.be/97Lvn8LoXYE"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <AppSecondaryButton icon={<ArrowForwardRoundedIcon />}>
                <Typography variant="h6"> Cathedral Gardens</Typography>
              </AppSecondaryButton>
            </a>
          </Grid>
          <Grid item xs={12} sm={6}>
            <img
              src={clientTwo}
              alt="client"
              style={{
                height: "50vh",
                width: "100%",
                objectFit: "cover",
                borderRadius: 10,
              }}
            />
            <AppSecondaryButton icon={<ArrowForwardRoundedIcon />}>
              <Typography variant="h6"> Barrons Court</Typography>
            </AppSecondaryButton>
          </Grid>
          <Grid item xs={12} sm={6}>
            <img
              src={clientThree}
              alt="client"
              style={{
                height: "50vh",
                width: "100%",
                objectFit: "cover",
                borderRadius: 10,
              }}
            />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://loveinvestments-my.sharepoint.com/:f:/g/personal/richard_loveinvestments_co_uk/Eh0Hd2_WeMJCuk3wKrkygUAB0dT4grQsr47UJ4vE3EBoXQ?e=5S2Cda"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <AppSecondaryButton icon={<ArrowForwardRoundedIcon />}>
                <Typography variant="h6">Bridge House</Typography>
              </AppSecondaryButton>
            </a>
          </Grid>
          <Grid item xs={12} sm={6}>
            <img
              src={clientFour}
              alt="client"
              style={{
                height: "50vh",
                width: "100%",
                objectFit: "cover",
                borderRadius: 10,
              }}
            />

            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://youtu.be/UaZ_KCXwB7I?si=1WNMbxqU-9rfJNj-"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <AppSecondaryButton icon={<ArrowForwardRoundedIcon />}>
                <Typography variant="h6">Empire House</Typography>
              </AppSecondaryButton>
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
