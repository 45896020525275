import React from "react";
import "./App.css";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import AppTheme from "./styles/appTheme";
import Routes from "./pages/Routes";
import useStylesBase from "./styles/stylesBase";

function App() {
  const classesBase = useStylesBase();

  return (
    <div className="App">
      <ThemeProvider theme={AppTheme}>
        <CssBaseline />
        <div className={classesBase.root}>
          <Routes />
        </div>
      </ThemeProvider>
    </div>
  );
}

export default App;
