import { SvgIcon } from "@material-ui/core";
import React from "react";

export default function Engagement() {
  return (
    <SvgIcon
      style={{ fontSize: 100 }}
      xmlns="http://www.w3.org/2000/svg"
      width="140"
      height="112"
      viewBox="0 0 140 112"
    >
      <path
        id="Icon_awesome-network-wired"
        data-name="Icon awesome-network-wired"
        d="M140,57.75v-3.5a3.5,3.5,0,0,0-3.5-3.5H75.25V42H91a7,7,0,0,0,7-7V7a7,7,0,0,0-7-7H49a7,7,0,0,0-7,7V35a7,7,0,0,0,7,7H64.75v8.75H3.5A3.5,3.5,0,0,0,0,54.25v3.5a3.5,3.5,0,0,0,3.5,3.5H26.25V70H14a7,7,0,0,0-7,7v28a7,7,0,0,0,7,7H49a7,7,0,0,0,7-7V77a7,7,0,0,0-7-7H36.75V61.25h66.5V70H91a7,7,0,0,0-7,7v28a7,7,0,0,0,7,7h35a7,7,0,0,0,7-7V77a7,7,0,0,0-7-7H113.75V61.25H136.5A3.5,3.5,0,0,0,140,57.75ZM56,28V14H84V28ZM42,98H21V84H42Zm77,0H98V84h21Z"
        fill="#ed2228"
      />
    </SvgIcon>
  );
}
