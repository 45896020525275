import { SvgIcon } from "@material-ui/core";
import React from "react";

export default function ThreeSixtyIcon() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="201.011"
      height="166.802"
      viewBox="0 0 201.011 166.802"
      style={{ fontSize: 100 }}
    >
      <g
        id="Group_73"
        data-name="Group 73"
        transform="translate(-338.369 -1944.587)"
      >
        <g id="Group_74" data-name="Group 74">
          <g
            id="_360-degres"
            data-name="360-degres"
            transform="translate(301.697 1990.484)"
          >
            <g
              id="Group_72"
              data-name="Group 72"
              transform="translate(16.84 9.838)"
            >
              <path
                id="Path_91"
                data-name="Path 91"
                d="M107.977,112.3v-.824c0-2.906-1.779-3.47-4.164-3.47-1.475,0-1.952-1.3-1.952-2.6s.477-2.6,1.952-2.6c1.648,0,3.383-.217,3.383-3.73,0-2.516-1.431-3.123-3.21-3.123-2.125,0-3.209.521-3.209,2.212,0,1.474-.651,2.472-3.167,2.472-3.123,0-3.513-.651-3.513-2.733,0-3.382,2.428-7.763,9.889-7.763,5.509,0,9.673,1.995,9.673,7.851,0,3.166-1.171,6.116-3.34,7.113a6.287,6.287,0,0,1,4.424,6.376v.824c0,7.113-4.9,9.8-10.974,9.8-7.46,0-10.323-4.554-10.323-8.2,0-1.952.824-2.473,3.209-2.473,2.776,0,3.47.607,3.47,2.256,0,2.039,1.909,2.516,3.86,2.516C106.935,116.2,107.977,115.117,107.977,112.3Zm0,0"
                transform="translate(-8.275 -125.323)"
                fill="#ed2228"
              />
              <path
                id="Path_92"
                data-name="Path 92"
                d="M230.07,111.473v.391c0,7.46-4.642,10.236-10.627,10.236s-10.671-2.776-10.671-10.236V100.369c0-7.46,4.814-10.236,11.061-10.236,7.33,0,10.236,4.554,10.236,8.154,0,2.082-1,2.732-3.167,2.732-1.865,0-3.513-.477-3.513-2.472,0-1.648-1.734-2.516-3.773-2.516-2.559,0-4.078,1.345-4.078,4.337v3.9c1.388-1.518,3.34-1.908,5.423-1.908,4.944,0,9.108,2.169,9.108,9.109Zm-14.531.868c0,2.993,1.475,4.294,3.9,4.294s3.86-1.3,3.86-4.294v-.391c0-3.167-1.431-4.381-3.9-4.381-2.342,0-3.86,1.128-3.86,3.991Zm0,0"
                transform="translate(-97.275 -125.322)"
                fill="#ed2228"
              />
              <path
                id="Path_93"
                data-name="Path 93"
                d="M325.055,111.863V100.369c0-7.46,4.641-10.236,10.627-10.236s10.67,2.776,10.67,10.236v11.495c0,7.46-4.684,10.236-10.67,10.236S325.055,119.324,325.055,111.863Zm14.53-11.495c0-2.993-1.474-4.337-3.9-4.337s-3.86,1.345-3.86,4.337v11.495c0,2.993,1.431,4.337,3.86,4.337s3.9-1.345,3.9-4.337Zm0,0"
                transform="translate(-187.012 -125.292)"
                fill="#ed2228"
              />
              <path
                id="Path_94"
                data-name="Path 94"
                d="M419.625,20.546A10.273,10.273,0,1,1,429.9,10.273,10.284,10.284,0,0,1,419.625,20.546Zm0-13.7a3.424,3.424,0,1,0,3.424,3.424A3.428,3.428,0,0,0,419.625,6.849Zm0,0"
                transform="translate(-252.065 -55.735)"
                fill="#ed2228"
              />
            </g>
          </g>
          <g
            id="Icon_feather-camera"
            data-name="Icon feather-camera"
            transform="translate(410.162 2006.015)"
          >
            <path
              id="Path_54"
              data-name="Path 54"
              d="M58.926,46.264a5.221,5.221,0,0,1-5.221,5.221H6.721A5.221,5.221,0,0,1,1.5,46.264V17.551a5.221,5.221,0,0,1,5.221-5.221H17.162L22.382,4.5H38.044l5.221,7.831H53.705a5.221,5.221,0,0,1,5.221,5.221Z"
              transform="translate(-1.5 -4.5)"
              fill="none"
              stroke="#ed2228"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="6"
            />
            <path
              id="Path_55"
              data-name="Path 55"
              d="M32.882,23.941A10.441,10.441,0,1,1,22.441,13.5,10.441,10.441,0,0,1,32.882,23.941Z"
              transform="translate(6.272 2.162)"
              fill="none"
              stroke="#ed2228"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="6"
            />
          </g>
          <g
            id="_360-degres-2"
            data-name="360-degres"
            transform="translate(338.369 1878.661)"
          >
            <path
              id="Path_95"
              data-name="Path 95"
              d="M313.184,219.158a5.889,5.889,0,0,1-.738-11.73c18.609-2.371,35.273-7.413,46.922-14.2,10.654-6.206,16.52-13.5,16.52-20.546,0-7.762-6.864-14.112-12.625-18.075a5.889,5.889,0,1,1,6.676-9.7c11.6,7.978,17.727,17.583,17.727,27.779,0,11.573-7.734,22.2-22.367,30.721-13.172,7.674-30.933,13.106-51.363,15.709a6.254,6.254,0,0,1-.751.046Zm0,0"
              transform="translate(-186.654 -3.75)"
              fill="#ed2228"
            />
            <path
              id="Path_96"
              data-name="Path 96"
              d="M99.07,206.971l-15.7-15.7a5.889,5.889,0,1,0-8.329,8.327l4.589,4.589c-17.593-1.817-33.576-5.914-45.953-11.865-13.914-6.691-21.9-15.216-21.9-23.387,0-6.93,5.714-14.134,16.089-20.283a5.889,5.889,0,1,0-6.006-10.132C3.793,149.225,0,160.966,0,168.93c0,13.066,10.146,25.142,28.569,34,14.3,6.875,32.8,11.48,52.947,13.263l-6.479,6.479A5.889,5.889,0,0,0,83.366,231l15.7-15.7a5.891,5.891,0,0,0,0-8.329Zm0,0"
              fill="#ed2228"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
