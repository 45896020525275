import { Container, Grid, Typography } from "@material-ui/core";
import React from "react";
import AppSecondaryButton from "../appCompnents/buttons/AppSecondaryButton";
import { threeSixty, photography, video, drone } from "../assets/about";
import CameraIcon from "../assets/about/CameraIcon";
import DroneIcon from "../assets/about/DroneIcon";
import ThreeSixtyIcon from "../assets/about/ThreeSixtyIcon";
import VideoIcon from "../assets/about/VideoIcon";
import useStylesBase from "../styles/stylesBase";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import { Link } from "react-router-dom";

export default function About() {
  const classesBase = useStylesBase();

  return (
    <Container>
      <Grid container>
        <Grid item xs={12} className={classesBase.header}>
          <Typography variant="h1">About</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" className={classesBase.content}>
            Love InDigital is run by Richard Beecher, a Senior Film, Digital and
            Production Officer at UWTSD.
            <br />
            <br /> Richard originally served in the British Army as a
            Reconnaissance Soldier, and represented the Regiment, Army and
            combined services in several extreme sports and events. This
            experience moved over to his civilian life where he was involved in
            extreme sports filmmaking from Northern America to European Ski
            resorts. <br />
            <br /> His previous experience fuelled a passion for filmmaking and
            creativity and has received many accolades, including the
            prestigious Alan Smithee Award for ‘Academic Achievement’ after
            gaining a 1st Class Honors at the University of Wales, and his
            award-winning documentary “Twm Sion Cati” at film festivals across
            the UK. He is also fortunate to have a film displayed at the Tate
            Modern in London. <br />
            <br />
            His enthusiasm for filmmaking, especially in marketing and media,
            has continued to develop and resulted in him setting up Love
            InDigital with his business partner, Peter Vidler, Director of Love
            Investments. <br />
            <br /> Richard primarily focuses on Digital Marketing for the social
            media platforms, leading on all digital film for the marketing
            department of UWTSD for the past 12 years and working across the
            globe, in locations such as Malaysia, Greece, Cyprus and Germany to
            name a few. <br />
            <br /> He has worked combining drone filming with ground footage
            creating a Cinematic feel and look. <br />
            <br /> He has had the honour of filming for the Harry Potter
            experience with his 360° drone and developing state of the art
            immersive experiences. <br />
            <br />
            On average Richard will produce over 340 social media films each
            year, his skills in visualizing the end product along with his
            skills of fast-paced editing allows him to create from concept to
            creation in a super quick turnaround in this fast-paced industry.
            <br />
            <br /> During Richard’s spare time he regularly teaches Broadcast
            and Media Production companies the art of editing and turning around
            quick projects in Adobe Premiere Pro and Photoshop for social media
            platforms. <br />
            <br />
            <span>
              Richard says{" "}
              <i>
                {" "}
                “Making social media films isn’t like any other filming or
                editing. You have about 30-40 second to tell your story with
                only 3 seconds to get their attention”.
              </i>{" "}
            </span>{" "}
            <br />
            <br />
            Love InDigital was established with a merger between Cwtch
            Productions which focuses on the corporate and wedding industry,
            along with Love Investments who focus on new developments and
            property resale. Combining the expertise of both has opened up
            varied and exciting opportunities.
          </Typography>
        </Grid>
        <Grid item xs={7} sm={6} className={classesBase.centerCenter}>
          <div>
            <CameraIcon />
            <Typography variant="h4" style={{ margin: 10 }}>
              Photography
            </Typography>
            <AppSecondaryButton
              to="/digitalPhotography"
              icon={<ArrowForwardRoundedIcon />}
            >
              View Services
            </AppSecondaryButton>
          </div>
        </Grid>
        <Grid item xs={5} sm={6}>
          <img
            src={photography}
            alt="photography"
            style={{ height: "50vh", width: "100%", objectFit: "cover" }}
          />
        </Grid>

        <Grid item xs={5} sm={6}>
          <img
            src={threeSixty}
            alt="360 photography"
            style={{ height: "50vh", width: "100%", objectFit: "cover" }}
          />
        </Grid>
        <Grid item xs={7} sm={6} className={classesBase.centerCenter}>
          <div>
            <ThreeSixtyIcon />
            <Typography variant="h4" style={{ margin: 10 }}>
              360 Images
            </Typography>
            <AppSecondaryButton
              to="/threeSixty"
              icon={<ArrowForwardRoundedIcon />}
            >
              View Services
            </AppSecondaryButton>
          </div>
        </Grid>

        <Grid item xs={7} sm={6} className={classesBase.centerCenter}>
          <div>
            <DroneIcon />
            <Typography variant="h4">Drone Footage</Typography>
            <AppSecondaryButton
              to={"/dronePhotography"}
              icon={<ArrowForwardRoundedIcon />}
            >
              View Services
            </AppSecondaryButton>
          </div>
        </Grid>
        <Grid item xs={5} sm={6}>
          <img
            src={drone}
            alt="drone photography"
            style={{ height: "50vh", width: "100%", objectFit: "cover" }}
          />
        </Grid>

        <Grid item xs={5} sm={6}>
          <img
            src={video}
            alt="video production"
            style={{ height: "50vh", width: "100%", objectFit: "cover" }}
          />
        </Grid>

        <Grid item xs={7} sm={6} className={classesBase.centerCenter}>
          <div>
            <VideoIcon />
            <Typography variant="h4">Video Production</Typography>
            <AppSecondaryButton
              to="/videoProduction"
              icon={<ArrowForwardRoundedIcon />}
            >
              View Services
            </AppSecondaryButton>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
