import { SvgIcon } from "@material-ui/core";
import React from "react";

export default function Video() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="511"
      height="202.227"
      viewBox="0 0 511 202.227"
      style={{ fontSize: 100 }}
    >
      <g id="Group_73" data-name="Group 73" transform="translate(-226.5 -2733)">
        <g id="Group_72" data-name="Group 72">
          <g id="Group_71" data-name="Group 71">
            <g id="drone-svgrepo-com" transform="translate(37.5 2592.972)">
              <path
                id="Path_89"
                data-name="Path 89"
                d="M503.5,144.028H439v-.5a23.5,23.5,0,1,0-47,0v.5H327.5a7.5,7.5,0,0,0,0,15H384v8.5c0,.168.01.333.013.5H126.987c0-.167.013-.332.013-.5v-8.5h56.5a7.5,7.5,0,0,0,0-15H119v-.5a23.5,23.5,0,0,0-47,0v.5H7.5a7.5,7.5,0,0,0,0,15H64v8.5a31.49,31.49,0,0,0,58.906,15.5h47.188l7,20.991a39.449,39.449,0,0,0,37.473,27.009H248v18.234c-9.29,3.138,24.29,3.138,15,0V231.028h33.436a39.449,39.449,0,0,0,37.473-27.009l7-20.991h47.188A31.49,31.49,0,0,0,447,167.528v-8.5h56.5a7.5,7.5,0,1,0,0-15Zm-416.5-.5a8.5,8.5,0,0,1,17,0v.5H87Zm8.5,40.5a16.519,16.519,0,0,1-16.5-16.5v-8.5h33v8.5A16.519,16.519,0,0,1,95.5,184.028Zm160,79Zm64.178-63.752a24.468,24.468,0,0,1-23.243,16.752H214.564a24.468,24.468,0,0,1-23.243-16.752L185.9,183.028H325.093ZM407,143.528a8.5,8.5,0,0,1,17,0v.5H407Zm25,24a16.5,16.5,0,0,1-33,0v-8.5h33Z"
                transform="translate(189 20)"
                fill="#ed2228"
              />
            </g>
          </g>
        </g>
        <g
          id="Icon_feather-video"
          data-name="Icon feather-video"
          transform="translate(449.5 2857.5)"
        >
          <path
            id="Path_3"
            data-name="Path 3"
            d="M56.614,10.5,24,33.8l32.614,23.3Z"
            transform="translate(47.386 6.318)"
            fill="none"
            stroke="#ed2228"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="10"
          />
          <path
            id="Path_4"
            data-name="Path 4"
            d="M10.818,7.5h51.25a9.318,9.318,0,0,1,9.318,9.318V63.409a9.318,9.318,0,0,1-9.318,9.318H10.818A9.318,9.318,0,0,1,1.5,63.409V16.818A9.318,9.318,0,0,1,10.818,7.5Z"
            transform="translate(0)"
            fill="none"
            stroke="#ed2228"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="10"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
