import { SvgIcon } from "@material-ui/core";
import React from "react";

export default function Sco() {
  return (
    <SvgIcon
      style={{ fontSize: 100 }}
      xmlns="http://www.w3.org/2000/svg"
      width="112"
      height="112"
      viewBox="0 0 112 112"
    >
      <path
        id="Icon_open-magnifying-glass"
        data-name="Icon open-magnifying-glass"
        d="M48.56.094a48.56,48.56,0,1,0,0,97.12,48,48,0,0,0,23.031-5.688,13.875,13.875,0,0,0,1.8,1.8L87.27,107.2a14.152,14.152,0,1,0,19.979-19.979L93.374,73.35a13.874,13.874,0,0,0-2.22-1.8,47.907,47.907,0,0,0,6.1-23.031A48.614,48.614,0,0,0,48.7-.045Zm0,13.874A34.522,34.522,0,0,1,83.246,48.654a34.791,34.791,0,0,1-9.157,23.864l-.416.416a13.873,13.873,0,0,0-1.8,1.8,34.718,34.718,0,0,1-23.448,8.741,34.686,34.686,0,1,1,0-69.372Z"
        transform="translate(0 0.045)"
        fill="#ed2228"
      />
    </SvgIcon>
  );
}
