import { SvgIcon } from "@material-ui/core";
import React from "react";

export default function Detail() {
  return (
    <SvgIcon
      style={{ fontSize: 100 }}
      id="image_search-24px"
      xmlns="http://www.w3.org/2000/svg"
      width="121.309"
      height="121"
      viewBox="0 0 121.309 121"
    >
      <path
        id="Path_42"
        data-name="Path 42"
        d="M0,0H121.309V121H0Z"
        fill="none"
      />
      <path
        id="Path_43"
        data-name="Path 43"
        d="M0,0H121.309V121H0Z"
        fill="none"
      />
      <path
        id="Path_44"
        data-name="Path 44"
        d="M82.873,57.458V92.75H12.109V22.167H37.483a31.709,31.709,0,0,1,2.426-10.083h-27.8A10.126,10.126,0,0,0,2,22.167V92.75a10.126,10.126,0,0,0,10.109,10.083H82.873A10.126,10.126,0,0,0,92.982,92.75V67.542ZM75.291,82.667h-55.6l13.9-17.8,9.907,11.9L57.4,58.92Zm14.153-45.93a22.443,22.443,0,0,0,3.538-12.05,22.745,22.745,0,1,0-22.8,22.688,22.454,22.454,0,0,0,12.08-3.529l15.77,15.73,7.177-7.159Zm-19.207.555a12.6,12.6,0,1,1,12.636-12.6A12.625,12.625,0,0,1,70.236,37.292Z"
        transform="translate(8.109 8.083)"
        fill="#ed2228"
      />
    </SvgIcon>
  );
}
